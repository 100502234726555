import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Contacts from '../components/Contacts';
import AccordionRichText from '../components/AccordionRichText';
import withAccordionGroup from '../hoc/with-accordion-group';
import VisualComposer from '../components/VisualComposer';

const AccordionGroup = withAccordionGroup(AccordionRichText);

const Contatti = ({ data, pageContext }) => {
  const { datoCmsContactPage } = data;
  const {
    title,
    coverImage,
    coverImageMobile,
    seoMetaTags,
    contactModularBlock
  } = datoCmsContactPage;

  const accordion = { ...datoCmsContactPage.accordionsGroup[0] };
  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Contact Page"
      canonical={pageContext.canonical}
    >
      <HeaderPage
        isRoot
        title={title}
        model="Unicef"
        coverImage={coverImage}
        coverImageMobile={coverImageMobile}
      />
      <div className="page-wrapper">
        <Contacts contactsRows={contactModularBlock} />
        {
          accordion.listAccordion && (
            <div className="contacts__accordion">
              <div className="contacts__accordion-wrapper">
                <h2 className="heading-md contacts__accordion-title">{accordion.title}</h2>
                <AccordionGroup accordions={accordion.listAccordion} />
              </div>
            </div>
          )
        }
        {
          data && data.datoCmsContactPage.visualComposer && (
            <VisualComposer
              components={data.datoCmsContactPage.visualComposer}
            />
          )
        }
      </div>
    </Layout>
  );
};

export default Contatti;

export const CONTACTS_QUERY = graphql`
  query contactPage {
    datoCmsContactPage {
      title
      seoMetaTags {
        tags
      }
      coverImage {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 1920px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      coverImageMobile {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 767px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactModularBlock {
        id
        title
        contacts {
          id
          title
          richText
          linkTwitter
          linkMap
          linkInstagram
          linkFacebook
        }
      }
      accordionsGroup {
        id
        title
        label
        description
        marginBottom
        marginTop
        listAccordion {
          id
          title
          content
        }
      }
      visualComposer {
        ...BannerText
        ...Numbers
        ...CenteredNumbers
        ...Quote
        ...Spacing
      }
    }
  }
`;
